@font-face {
    font-family: "KPNMetric";
    src: url("KPNMetric-Light.otf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "KPNMetric";
    src: url("KPNMetric-Regular.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
 @font-face {
        font-family: "KPNMetric";
        src: url("KPNMetric-SemiBold.otf") format("truetype");
        font-weight: 700;
        font-style: normal;
}

  @font-face {
    font-family: "KPNMetric";
    src: url("KPNMetric-Bold.otf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "KPNMetric";
    src: url("KPNMetric-Black.otf") format("truetype");
    font-weight: 900;
    font-style: normal;
  }